import React from 'react';
import { object, string } from 'prop-types';
import classNames from 'classnames';

import { intlShape } from '../../../util/reactIntl';
import { stringify } from '../../../util/urlHelpers';
import { NamedLink } from '../../../components';

import css from './ListingTypeFilter.module.css';

const ListingTypeFilter = props => {
  const { className, rootClassName, urlQueryParams, intl } = props;
  const listingType = urlQueryParams?.listingType;
  const classes = classNames(rootClassName || css.root, className);

  const directoryText = intl.formatMessage({ id: 'Marketplace.directoryLabel' });
  const productText = intl.formatMessage({ id: 'Marketplace.productLabel' });
  const offerText = intl.formatMessage({ id: 'Marketplace.offerLabel' });

  return (
    <div className={classes}>
      <NamedLink
        className={css.link}
        activeClassName={listingType === 'directory' ? css.linkActive : ''}
        name="SearchPage"
        to={{ search: stringify({ pub_listingType: 'directory' }) }}
      >
        {directoryText}
      </NamedLink>
      <NamedLink
        className={css.link}
        activeClassName={listingType === 'product' ? css.linkActive : ''}
        name="SearchPage"
        to={{ search: stringify({ pub_listingType: 'product' }) }}
      >
        {productText}
      </NamedLink>
      <NamedLink
        className={css.link}
        activeClassName={listingType === 'offer' ? css.linkActive : ''}
        name="SearchPage"
        to={{ search: stringify({ pub_listingType: 'offer' }) }}
      >
        {offerText}
      </NamedLink>
    </div>
  );
};

ListingTypeFilter.defaultProps = {
  rootClassName: null,
  className: null,
};

ListingTypeFilter.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,

  // from useIntl
  intl: intlShape.isRequired,
};

export default ListingTypeFilter;
